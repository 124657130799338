import wts from '../../resources/images/whatsapp.png'
import { sendWhatsapp } from '../../resources/ts/services'


export const Wathsapp = () => {
   return (
      <div style={{
         position: 'fixed',
         zIndex: 2000,
         bottom: 30,
         right: 10,
         background: 'none',
         border: 'none',
         cursor: 'pointer',
         textDecoration: 'none'
      }}
         onClick={sendWhatsapp}
      >
         <img src={wts} height={70} alt='whats to click' />
      </div>
   )
}