import Instagram from "../../components/instagram/Instagram"


export const Contact = () => {
   return (
      <div
         id='contacts'
         style={{
            marginTop: 90,
            marginBottom: 90,
            justifyContent: 'center',
            display: 'flex'
         }}
      >
         <Instagram />
      </div>
   )
}
