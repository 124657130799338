import {
   Avatar,
   Grid,
   List,
   ListItem,
   ListItemAvatar,
   ListItemText,
   Typography
} from "@mui/material"

import CarRepairIcon from '@mui/icons-material/CarRepair';
import WorkIcon from '@mui/icons-material/Work';
import SpeedIcon from '@mui/icons-material/Speed';

import { styleText } from "../../template/style"
import { colors } from "../../template/colors"
import { GroupServices } from "../groupServices/groupServices";

export const Services = () => {
   return (
      <Grid
         container
         spacing={2}
         alignContent='center'
         textAlign='center'
         mt={5}
         sx={{
            height: { md: '500px' }
         }}
      >
         <Grid item
            xs={12}
            md={6}
            mb={3}
            sx={{
               display: 'flex',
               justifyContent: 'center',
               height: { md: '300px' }
            }}
         >
            <GroupServices />
         </Grid>
         <Grid item
            xs={12}
            md={6}
            sx={{
               bgcolor: '#115740',
               borderTopLeftRadius: 100,
               height: '100%'
            }}
         >
            <Typography
               variant="h4"
               color={colors.text_white}
               sx={{
                  ...styleText,
                  textAlign: 'center',
                  width: { md: '70%', xs: '100%' },
                  mb: 2,
                  mt: 3,
                  ml: 2,
                  mr: 2
               }}
            >
               DIFERENCIAIS QUE <strong>OFERECEMOS</strong>
            </Typography>
            <List sx={{
               bgcolor: 'background.paper',
               borderTopLeftRadius: 100,
               paddingLeft: 10
            }}>
               <ListItem>
                  <ListItemAvatar>
                     <Avatar sx={{ bgcolor: colors.green_avatar }}>
                        <WorkIcon />
                     </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                     primary='Custo benefício'
                     secondary='Melhores preços do mercado'
                  />
               </ListItem>
               <ListItem>
                  <ListItemAvatar>
                     <Avatar sx={{ bgcolor: colors.green_avatar }}>
                        <CarRepairIcon />
                     </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                     primary='Profissionais qualificados'
                     secondary='Motoristas especializados em mecânica'
                  />
               </ListItem>
               <ListItem>
                  <ListItemAvatar>
                     <Avatar sx={{ bgcolor: colors.green_avatar }}>
                        <SpeedIcon />
                     </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                     primary='Velocidade no atendimento'
                     secondary='Garatimos um atendimento de qualidade em um momento de necessidade'
                  />
               </ListItem>
            </List>
         </Grid>
      </Grid >
   )
}
