
export function sendWhatsapp() {
   const numeroTelefone = '+5511993269784';
   const mensagem = 'Olá, gostaria de solicitar um serviço.';
   const url = `https://api.whatsapp.com/send?phone=${numeroTelefone}&text=${encodeURIComponent(mensagem)}`;
   window.open(url, '_blank');
}


export function sendInstagram() {
   const url = `https://www.instagram.com/hmguinchos/`;
   window.open(url, '_blank');
}