import {
   Card,
   CardContent,
   CardMedia,
   Grid,
   ImageList,
   ImageListItem,
   Typography
}
   from "@mui/material"

import bg1 from '../../resources/images/g1.jpg'
import bg2 from '../../resources/images/g2.jpg'
import bg3 from '../../resources/images/g3.jpg'
import bg5 from '../../resources/images/g5.jpg'
import bg6 from '../../resources/images/g6.jpg'

const itemData = [
   {
      img: bg6,
      title: 'Reboque',
      author: '',
   },
   {
      img: bg3,
      title: 'Mushrooms',
      author: '@silverdalex',
   },
   {
      img: bg5,
      title: 'Tomato basil',
      author: '@shelleypauls',
   },
   {
      img: bg1,
      title: 'Sea star',
      author: '@peterlaster',
   }
];

export const GroupServices = () => {
   return (
      <div id="services">
         <Grid container spacing={2} p={2} gap={2}
            sx={{
               display: 'flex',
               justifyContent: 'center',
            }}
         >
            <Grid item md={3} >
               <CardService
                  image={bg1}
                  description={`Entre em contato e conheça mais sobre nossos serviços`}
                  title='Reboque'
               />
            </Grid>
            <Grid item md={3} >
               <CardService
                  image={bg2}
                  description={`Resgate de veículos em situações de acidentes, carros, motos, vans etc..`}
                  title='Acidentes'
               />
            </Grid>
            <Grid item md={3} >
               <CardService
                  image={bg3}
                  description={`Transporte de materiais. Entre em contato para saber mais`}
                  title='Translado'
               />
            </Grid>
         </Grid>
      </div>
   )
}


export const GroupImages = () => {
   return (
      <div style={{
         display: 'flex',
         justifyContent: 'center'
      }}>
         <TitlebarBelowImageList />
      </div>
   )
}


const CardService = ({ image, description, title }: any) => {
   return (
      <Card sx={{ maxWidth: 345 }}>
         <CardMedia
            sx={{
               height: 140,
            }}
            image={image}
            title="bg glaucar"
         />
         <CardContent>
            <Typography gutterBottom variant="h5" component="div">
               {title}
            </Typography>
            <Typography variant="body2" color="text.secondary">
               {description}
            </Typography>
         </CardContent>
      </Card>
   )
}

function TitlebarBelowImageList() {
   return (
      <ImageList sx={{ width: { xs: window.innerWidth, md: 650 }, height: 450 }}>
         {itemData.map((item, index) => (
            <ImageListItem key={index}>
               <img
                  srcSet={item.img}
                  src={item.img}
                  alt={item.title}
                  loading="lazy"
                  height='20px'
               />

            </ImageListItem>
         ))}
      </ImageList>
   );
}

