import { Typography } from "@mui/material"

export const TextInformationBigger = ({ 
   description, 
   type,
   color
}: any) => {
   return (
      <Typography
         variant={type}
         component="a"
         sx={{
            fontFamily: 'monospace',
            fontWeight: 700,
            letterSpacing: '.0rem',
            color: color,
            textDecoration: 'none',
         }}>
            {description}
      </Typography>
   )
}