import { Grid, Stack } from "@mui/material"
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import PhoneCallbackIcon from '@mui/icons-material/PhoneCallback';
import PhoneAndroidOutlinedIcon from '@mui/icons-material/PhoneAndroidOutlined';
import { TextInformationBigger } from "../../components/textInformation/TextInformation";
import { colors } from "../../template/colors";


export function Informations() {
   return (
      <Grid container spacing={0} >
         <Grid item xs={12} sm={4} justifyItems='center' height={150}>
            <ItemListGrid bg_color={colors.bg_red}
               icon={<AccessTimeFilledIcon sx={{
                  color: 'white',
                  fontSize: '70px',
               }}
               />}
               description='ATENDIMENTO IMEDIATO'
               colorText={colors.text_white}
            />
         </Grid>
         <Grid item xs={12} sm={4} height={150}>
            <ItemListGrid bg_color={colors.bg_dark}
               icon={<PhoneAndroidOutlinedIcon sx={{
                  color: 'white',
                  fontSize: '70px',
               }}
               />}
               description='(11) 99326-9784'
               colorText={colors.text_white}
            />
         </Grid>
         <Grid item xs={12} sm={4} height={150}>
            <ItemListGrid bg_color={colors.bg_red}
               icon={<PhoneCallbackIcon sx={{
                  color: 'white',
                  fontSize: '70px',
               }}
               />}
               description='24/7 ATENDIMENTO 24H'
            />
         </Grid>
      </Grid>
   );
}

const ItemListGrid = ({ icon, description, bg_color, colorText='black' }: any) => {
   return (
      <Stack sx={{
         bgcolor: bg_color,
         height: '100%'
      }}
         direction='column'
         alignItems='center'
         justifyContent='center'
         spacing={2}
      >
         {icon}
         <TextInformationBigger
            description={description}
            type='body1'
            color={colorText}
         />
      </Stack>
   )
}